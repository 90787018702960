<template >
  <div class="property">
    <div class="balance">
      <p>{{$t('home.ye')}}（USDT）</p>
      <div @click="jumpUrl('/assetDetails')" class="details">{{$t('home.mx')}}</div>
      <div class="balance-number">$<span>{{assetsInfo.balance}}</span></div>
      <div class="flex-between" style="justify-content: space-around">
        <van-button class="withdrawal" @click="getWithdrawalInfo">{{$t('home.tb')}}</van-button>
        <van-button class="recharge" @click="getRechargeInfo">{{$t('home.cz')}}</van-button>
      </div>
    </div>
    <div class="income">
      <p>{{ $t('home.syjl') }}</p>
      <ul>
        <li class="flex-between" @click="getDetails(0)">
          <div>
            <img src="../../assets/images/jtsy.png" alt="">
            {{ $t('home.jtsy') }}
          </div>
          ${{staticGains}}
        </li>
        <li class="flex-between" @click="getDetails(1)">
          <div>
            <img src="../../assets/images/dsjl.png" alt="">
            {{ $t('home.dsjl') }}
          </div>
          ${{algebraicRewards}}
        </li>
        <li class="flex-between" @click="getDetails(2)">
          <div>
            <img src="../../assets/images/vip.png" alt="">
            {{ $t('home.vipjl') }}
          </div>
          ${{VIPRewards}}
        </li>
      </ul>
    </div>
    <div class="statistics">
      <p>{{ $t('home.tj') }}</p>
      <div class="accumulate">
        <div v-for="(item,i) in accumulate" :key="i" :style="{backgroundImage:'url('+item.background+')'}" class="accumulate-item">
          <p>{{item.num}}</p>
          {{item.text}}
        </div>
      </div>
    </div>

    <!--  提币-->
    <van-popup v-model:show="showWithdrawal"  closeable :close-on-click-overlay="false"  @close="close">
      <div class="transaction">
        <div class="title">{{ $t('home.tb') }}</div>
        <van-cell-group inset>
          <van-field v-model="withdrawal"  center :placeholder="$t('home.tbp')" @blur="changeWithdrawal(withdrawal,'withdrawal')"/>
        </van-cell-group>
        <div class="num flex-between">
          STT/USDT：
          <strong>{{price}}</strong>
        </div>
        <div class="num flex-between">
          {{ $t('home.khdstt') }}：
          <strong style="color: #AF65EE">{{getSTT}}</strong>
        </div>
        <div class="confirm-btn">
          <van-button class="confirm" @click="getWithdrawal">{{ $t('home.qrtq') }}</van-button>
        </div>
        <div class="tips">
          {{ $t('home.tbtips') }}
        </div>
      </div>

    </van-popup>
<!--密码-->
    <van-popup v-model:show="show" closeable position="center" :close-on-click-overlay="false" @close="close">
      <div class="popup-reservation">
        <div class="title">{{ $t('home.mm') }}</div>
        <van-cell-group inset>
          <van-field center :placeholder="$t('home.srmm')" v-model="password" maxlength="6" type="password"/>
        </van-cell-group>
        <div class="confirm-btn">
          <van-button class="confirm" @click="submitWithdrawal">{{ $t('home.qd') }}</van-button>
        </div>
      </div>
    </van-popup>
<!--    充值-->
    <van-popup v-model:show="showRecharge"  closeable :close-on-click-overlay="false" @close="close">
      <div class="transaction">
        <div class="title">{{ $t('home.cz') }}</div>
        <van-cell-group inset>
          <van-field v-model="recharge"  center :placeholder="$t('home.czp')" @blur="changeWithdrawal(recharge)"/>
        </van-cell-group>
        <div class="num flex-between">
          STT/USDT：
          <strong>{{price}}</strong>
        </div>
        <div class="confirm-btn">
          <van-button class="confirm" @click="transferSTT">{{$t('home.zf')}}STT：{{getSTT}}</van-button>
        </div>
        <div class="tips">
          {{$t('home.cztips')}}
        </div>
      </div>

    </van-popup>
  </div>

</template>
<script setup>
import {ref, onBeforeMount, onBeforeUnmount, getCurrentInstance} from 'vue'
const { $t } = getCurrentInstance().proxy;
import {div, getPublicKey, get4number, get2String} from '../../utils/decimalTool'
// eslint-disable-next-line no-unused-vars

import { useRouter} from "vue-router";
import {
  getSttUsdtPrice,
  getUserAssets,
  getUserOrderInterest,
  getUserRechargeInfo,
  getUserRewardDetail,
  setWithdraw
} from "../../api";
import {Toast} from "vant";
const router = useRouter()
const jumpUrl=(url)=>{
  router.push(url)
}
onBeforeMount(()=>{
  getHttp()
})
const assetsInfo=ref({})
const staticGains=ref('')
const algebraicRewards=ref('')
const VIPRewards=ref('')
const accumulate=ref([])
const getHttp=()=>{
  getUserAssets().then(res=>{
    res.data.balance=get4number( res.data.balance)
    assetsInfo.value=res.data
    accumulate.value=[
      {
        num:res.data.totalRechargeAmount,
        text:$t('home.ljcz'),
        background:require('../../assets/images/ljcz.png')
      },
      {
        num:res.data.totalWithDrawApplyAmount,
        text:$t('home.ljtx'),
        background:require('../../assets/images/ljtx.png')
      },{
        num:res.data.orderTotalAmount,
        text:$t('home.ljyy'),
        background:require('../../assets/images/ljyy.png')
      },{
        num:res.data.orderTotalBalance,
        text:$t('home.yysy'),
        background:require('../../assets/images/yysy.png')
      },{
        num:res.data.orderItemsTotalAmount,
        text:$t('home.bjzcz'),
        background:require('../../assets/images/bj_zcz.png')
      },{
        num:res.data.orderItemsTotalInterest,
        text:$t('home.lxzcz'),
        background:require('../../assets/images/lx_zcz.png')
      },{
        num:res.data.settleOrderItemsTotalAmount,
        text:$t('home.bjyjs'),
        background:require('../../assets/images/bj_yjs.png')
      },{
        num:res.data.settleOrderItemsTotalInterest,
        text:$t('home.lxyjs'),
        background:require('../../assets/images/lx_yjs.png')
      },
    ]
  })
  getUserOrderInterest({
    "pageNo":"1",
    "pageSize":"10",
  }).then(res=>{
    staticGains.value=res.data.info.total
  })
  getUserRewardDetail({
    "pageNo":"1",
    "pageSize":"10",
    "type":1,   //0：静态奖励 1：动态奖励 2：经理奖励
    "status":0   //0: 预发,  1:已结算,   2:已废弃
  }).then(res=>{
    algebraicRewards.value=res.data.info.total
  })
  getUserRewardDetail({
    "pageNo":"1",
    "pageSize":"10",
    "type":2,   //0：静态奖励 1：动态奖励 2：经理奖励
    "status":0   //0: 预发,  1:已结算,   2:已废弃
  }).then(res=>{
    VIPRewards.value=res.data.info.total
  })
}
const getDetails=(type)=>{
  router.push(type===0?'/interestReward':('/dynamicRewards?type='+type))
}
// 提币
const price=ref(0)
const getSTT=ref(0)
const withdrawal=ref('')
const showWithdrawal=ref(false)
const  getWithdrawal=()=>{
  if(isNaN(withdrawal.value) ){
    Toast($t('home.qsrje'))
    return
  }
  if(getSTT.value==0 ){
    Toast($t('home.tb')+$t('home.getSTT'))
    return
  }
  show.value=true
}

const get2number=(val)=>{
  let reg = /^\d+(.\d{1,2})?$/g;
  let number
  if (reg.test(val)) { //正则匹配通过，提取有效文本
    number = val;
  } else { //正则匹配不通过，直接清空
    number = '';
    Toast($t('home.get2number'))
  }
  return number

}
const changeWithdrawal=(val,name)=>{

  if(name==='withdrawal'){
    withdrawal.value = get2number(val)
  }else{
    recharge.value =get2number(val)
  }

  let num=div(get2number(val),price.value)
  getSTT.value=get2String(num)
}
const getWithdrawalInfo=()=>{
  // withdrawal.value=0
  getSttUsdtPrice().then(res=>{
    price.value=res.data.price
    // getSTT.value=div(withdrawal.value,res.data.price)
    showWithdrawal.value=true

  })
}

// 密码
const show=ref(false)
const password=ref('')
const submitWithdrawal=()=>{
  setWithdraw({
    "amount":withdrawal.value,   //提现金额
    "payPwd":getPublicKey(password.value),        //支付密码
    "address":sessionStorage.getItem('address')   //提现地址

  }).then(res=>{
    if(res.code==='200'){
      withdrawal.value=0
      password.value=''
      Toast($t('home.success'))
      show.value=false
      showWithdrawal.value=false
      getHttp()
    }else{
      Toast(res.msg)
    }
  })
}

// 充值
const showRecharge=ref(false)
const recharge=ref('')
const getRechargeInfo=()=>{
  getSttUsdtPrice().then(res=>{
    price.value=res.data.price
    showRecharge.value=true
  })
}
const transferSTT=()=>{
  clearInterval(payTimer.value);
  console.log(getSTT.value)
  if(getSTT.value==0 ){
    Toast($t('home.cz')+$t('home.getSTT'))
    return
  }
  getUserRechargeInfo().then(res=>{
    if(res.code==='200'){
      if(recharge.value>0){
        transferFunction(res.data.address)
      }else{
        Toast($t('home.qsrje'))
      }

    }else{
      Toast(res.msg)
    }
  })
}
const payTimer=ref(null)
const transferFunction=(address)=>{
  const amount=Number(getSTT.value)
  window.statter.transferSTT(
      sessionStorage.getItem('address'),
      address,
      amount,
      '2002'
  )
  Toast.loading({
    duration:0,
    forbidClick:true,
    message:$t('home.loading')
  })
  payTimer.value=setInterval(()=>{
    if(sessionStorage.getItem('payMsg')!=null && sessionStorage.getItem('payMsg')==1001){
      showRecharge.value=false
      Toast($t('home.success'))
      clearInterval(payTimer.value);

    }else if(sessionStorage.getItem('payMsg')!=null) {
      clearInterval(payTimer.value);
      Toast.clear();
      let msg = sessionStorage.getItem('payMsg') + ''
      switch (msg) {
        case '1002' :
          Toast($t('home.failed'))
          break
        case '1003' :
          Toast($t('home.payMsg3'))
          break
        case '1004' :
          Toast($t('home.payMsg4'))
          break
        case '1005' :
          Toast($t('home.payMsg5'))
          break
        case '1006' :
          Toast($t('home.payMsg6'))
          break
        case '1007' :
          Toast($t('home.payMsg7'))
          break
      }
    }
  })
}
const close=()=>{
  password.value=''
  withdrawal.value=''
  recharge.value=''
  getSTT.value=0
}
onBeforeUnmount(()=>{
  clearInterval(payTimer.value);

})
</script>
<style scoped lang="less">
.flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.property{
  padding: 23px 18px 133px 18px;
  background-image: url("~@/assets/images/bg_pic.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  min-height: 100vh;
box-sizing: border-box;
}
.balance{
  background: #000000;
  border-radius: 46px;
  margin:0px 0px 34px 0px;
  padding: 30px 10px 34px 10px;
  position: relative;
  .details{
    font-weight: 500;
    font-size: 15px;
    color: #6FFEE8;
    position: absolute;
    top:31px;
    right: 22px;
  }
  p{
    font-weight: 500;
    font-size: 16px;
    color: #7E8D95;
    margin-bottom: 35px;
    margin-top: 0;
  }
  .balance-number{
    font-weight: bold;
    font-size: 23px;
    color: #7E8D95;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    span{
      font-weight: bold;
      font-size: 46px;
      color: #FFFFFF;
      margin-left: 7px;
    }
  }
  .withdrawal{
    width: 140px;
    height: 50px;
    background: #7E8D95;
    border-radius: 28px;
    font-weight: bold;
    font-size: 17px;
    color: #FFFFFF;
    border: none;
  }
  .recharge{
    width: 140px;
    height: 50px;
    background: linear-gradient(90deg, #57F6CD, #AF65EE);
    box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
    border-radius: 28px;
    font-weight: bold;
    font-size: 17px;
    color: #000000;
    border: none;

  }
}
.income{
  p{
    font-weight: bold;
    font-size: 16px;
    color: #7E8D95;
    margin-bottom: 27px;
    text-align: left;
  }
  ul{
    background: rgba(0,0,0,0.3);
    border-radius: 25px;
    padding: 2px 25px;
    li{
      border-bottom:1px solid #7E8D95;
      padding: 22px 0;
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
      div{
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        img{
          width: 42px;
          height: 42px;
          margin-right: 14px;
        }
      }
    }
    li:last-child{
      border-bottom: none;
    }
  }
}
.statistics{
  p{
    font-weight: bold;
    font-size: 16px;
    color: #7E8D95;
    margin-bottom: 25px;
    text-align: left;
  }
  .accumulate{
    overflow-x: scroll;
    display: flex;
    align-items: center;

    .accumulate-item{
      width: 129px;
      height: 83px;
      border-radius: 6px;
      background-size: 100% 100%;
      margin-right: 15px;
      font-weight: bold;
      font-size: 14px;
      color: rgba(255,255,255,0.7);
      text-align: center;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-shrink: 0;
      p{
        font-weight: bold;
        font-size: 21px;
        color: #FFFFFF;
        margin: 0 0 10px 0;
      }
    }

  }

}
::-webkit-scrollbar {
  display: none;
}
/deep/ .van-popup{
  background: transparent;
}
/deep/.van-popup__close-icon--top-right{
  top:30px;
  height: 24px;
}
.transaction{
  box-sizing: border-box;
  width: 312px;
  //height: 320px;
  background: #232A2E;
  border-radius: 25px;
  padding: 28px 19px 31px 19px;
  .title{
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 30px;
  }
  .num{
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    width: 90%;
    margin: 15px auto 0;
    strong{
      font-weight: bold;
      font-size: 14px;
      color: #6FFEE8;
    }
  }
  .tips{
    font-weight: 500;
    font-size: 12px;
    color: #7E8D95;
    margin-top: 17px;
  }
  .confirm-btn{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    .confirm{
      width: 187px;
      height: 56px;
      background: linear-gradient(90deg, #57F6CD, #AF65EE);
      box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
      border-radius: 28px;
      font-weight: bold;
      font-size: 17px;
      color: #000000;
      text-align: center;
      border: none;
    }
  }
  /deep/ .van-cell-group{
    width: 100%;
    height: 62px;
    background: #000000;
    border-radius: 31px;
  }
  /deep/ .van-cell-group--inset{
    margin: 0;
    .van-cell{
      font-weight: 500;
      font-size: 17px;
      color: #7E8D95;
      width: 264px;
      height: 62px;
      background: #000000;
      border-radius: 31px;
    }
    .van-field__control{
      text-align: center;
      font-weight: bold;
      font-size: 21px;
      color: #FFFFFF;
    }
  }
}
.popup-reservation{
  box-sizing: border-box;
  width: 312px;
  height: 260px;
  background: #232A2E;
  border-radius: 25px;
  padding: 28px 19px 0 19px;
  .title{
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 30px;
  }
  .confirm-btn{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    .confirm{
      width: 187px;
      height: 56px;
      background: linear-gradient(90deg, #57F6CD, #AF65EE);
      box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
      border-radius: 28px;
      font-weight: bold;
      font-size: 17px;
      color: #000000;
      text-align: center;
      line-height: 56px;
      border: none;
    }
  }
  /deep/ .van-cell-group{
    width: 264px;
    height: 62px;
    background: #000000;
    border-radius: 31px;
  }
  /deep/ .van-cell-group--inset{
    margin: 0;
    .van-cell{
      font-weight: 500;
      font-size: 17px;
      color: #7E8D95;
      width: 264px;
      height: 62px;
      background: #000000;
      border-radius: 31px;
    }
    .van-field__control{
      text-align: center;
      font-weight: bold;
      font-size: 21px;
      color: #FFFFFF;
    }
  }
}
/deep/.van-field__control::placeholder{
  font-weight: 500;
  font-size: 17px;
  color: #7E8D95;
}
</style>
