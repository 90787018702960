<template>
<div class="reservation-page">
  <div class="top-text">
    <div>{{$t('home.yysl')}}</div>
    <img  @click="showPopup" class="top-image" src="@/assets/images/guize.png" alt="">
  </div>
  <div class="input">
    <van-cell-group inset>
      <van-field center placeholder="0.00" v-model="amount"/>
    </van-cell-group>
  </div>

  <div class="all">
    <div>{{get4number(assetsInfo.balance)}}</div>
    <div class="all-text" @click="amount=assetsInfo.balance">{{$t('home.qb')}}</div>
  </div>
  <div class="tips" v-if="locale==='zh-HK'">{{$t('home.yyzd')}}{{assetsInfo.reservation_amount}}{{$t('home.zd')}}{{assetsInfo.reservation_max_amount}}，{{assetsInfo.reservation_multiple}}{{$t('home.dzs')}}</div>
  <div class="tips" v-else>{{$t('home.yyzd')}}{{assetsInfo.reservation_amount}}{{$t('home.zd')}}{{assetsInfo.reservation_max_amount}}，{{assetsInfo.reservation_multiple}}{{$t('home.dzs')}}</div>
  <div class="btn-box">
    <van-button class="btn"  @click="reservationBtn">{{$t('home.yysl')}}</van-button>
  </div>
  <div class="order-info">
    <div class="horizontal-line"></div>
    <div class="order-text">{{$t('home.ddjl')}}</div>
    <div class="horizontal-line"></div>
  </div>

  <div class="order-list">
    <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('home.noMore')"
        :loading-text="$t('home.loading')"
        :error-text="$t('home.failed')"
        @load="onLoad"
    >
      <div class="order-items" v-for="(item,i) in list" :key="i" @click="getDetails(item)">
        <div>{{$t('home.ddbh')}}：<span>{{item.orderNo}}</span></div>
        <div>{{$t('home.yysj')}}：<span>{{locale==='en-US'?toHHmmss(item.createTime,-5):toHHmmss(item.createTime,+8)}}</span></div>
        <div>{{$t('home.yysl')}}：<span>{{item.totalAmount}}</span></div>
        <div>{{$t('home.sssl')}}：<span>{{item.balance}}</span></div>
      </div>
    </van-list>

  </div>
  <van-popup v-model:show="show" closeable position="center" :close-on-click-overlay="false">
    <div class="popup-rule">
      <div class="title">{{$t('home.gz')}}</div>
      <div class="tips" v-if="locale==='zh-HK'">1、{{$t('home.yysl')}}{{assetsInfo.reservation_amount}}{{$t('home.start')}}，{{assetsInfo.reservation_multiple}}{{$t('home.gz1')}}</div>
      <div class="tips" v-else>1、{{$t('home.start')}}{{assetsInfo.reservation_amount}}，{{$t('home.gz0')}}{{assetsInfo.reservation_multiple}},{{$t('home.gz1')}}</div>
      <div class="tips">2、{{$t('home.gz2')}}</div>
      <div class="tips">3、{{$t('home.gz3')}}</div>
    </div>
  </van-popup>
  <van-popup v-model:show="showReservation" closeable position="center" :close-on-click-overlay="false">
    <div class="popup-reservation">
      <div class="title">{{$t('home.mm')}}</div>
      <van-cell-group inset>
        <van-field center :placeholder="$t('home.srmm')" v-model="password" maxlength="6" type="password"/>
      </van-cell-group>
      <div class="confirm-btn">
        <van-button class="confirm" @click="onsubmit">{{$t('home.qd')}}</van-button>
      </div>
    </div>
  </van-popup>
</div>
</template>

<script setup>
import { ref,onBeforeMount,getCurrentInstance } from 'vue';
import {getReservationOrderList, getUserAssets, setReservationOrder} from "../../api";
import {Toast} from 'vant'
import {getPublicKey,toHHmmss,get4number} from "../../utils/decimalTool";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
const { $t } = getCurrentInstance().proxy;
const {locale } = useI18n()
const show = ref(false);
const showReservation = ref(false);
const showPopup = () => {
  show.value = true;
};
const reservationBtn = () => {
  password.value=null
  showReservation.value = true;
};
const pageNo=ref(1)
const total=ref(0)
const list = ref([]);
const loading = ref(false);
const finished = ref(false);
const assetsInfo=ref({})
const getHttp=()=>{
  getUserAssets().then(res=>{
    assetsInfo.value=res.data
  })
  getReservationOrderList({
    "pageNo":pageNo.value,
    "pageSize":10,
  }).then(res=>{
    list.value.push(...res.data.orders)
    total.value=res.data.page.totalNum
  })
}
const onLoad = () => {
  // 异步更新数据
  // setTimeout 仅做示例，真实场景中一般为 ajax 请求
  setTimeout(() => {

    // 数据全部加载完成
    if (list.value.length >= total.value) {
      finished.value = true;
    }else {
      pageNo.value+=1
      getHttp()
      loading.value = false;

    }
  }, 1000);
};
const password=ref('')
const amount=ref(null)
const onsubmit=()=>{
  setReservationOrder({
    "amount":amount.value,
    payPwd:getPublicKey(password.value)//公钥加密后
}).then(res=>{
    if(res.code==='200'){
      showReservation.value=false
      amount.value=null
      pageNo.value=1
      list.value=[]
      finished.value=false
      Toast($t('home.success'))
      getHttp()
    }else{
      Toast(res.msg)
    }
  })
}
const router=useRouter()
const getDetails=(item)=>{
  router.push({
    path:'/reservationDetails',
    query:{
      id:item.id
    }
  })
}
onBeforeMount(()=>{
  getHttp()
})
</script>

<style scoped lang="less">
.reservation-page{
  box-sizing: border-box;
  height: calc(100vh - 90px);
  padding: 21px 18px;
  background-image: url("~@/assets/images/bg_pic.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  .top-text{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    .top-image{
      width: 15px;
      height: 15px;
      margin-left: 4px;
    }
  }
  .all{
    margin: 14px 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    color: #7E8D95;
    .all-text{
      margin-left: 8px;
      font-weight: 500;
      font-size: 14px;
      color: #6FFEE8;
      line-height: 19px;
    }
  }
  .tips{
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    color: #7E8D95;
  }
  .btn-box{
    margin: 35px 0 45px 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn{
    width: 322px;
    height: 56px;
    background: linear-gradient(90deg, #57F6CD, #AF65EE);
    box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
    border-radius: 28px;
    font-weight: bold;
    font-size: 17px;
    color: #000000;
    text-align: center;
    line-height: 56px;
    border: none;
  }
  .order-info{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    color: #7E8D95;
    .horizontal-line{
      width: 31px;
      height: 1px;
      background: #FFFFFF;
      opacity: 0.2;
    }
    .order-text{
      margin: 0 10px;
    }
  }
  .order-list{
    .order-items{
      margin-bottom: 21px;
      text-align: left;
      background: rgba(0,0,0,0.3);
      border-radius: 25px;
      padding: 30px 21px 7px 21px;
      &>div{
        margin-bottom: 23px;
        font-weight: 500;
        font-size: 15px;
        color: #7E8D95;
        span{
          font-weight: 400;
          font-size: 15px;
          color: #FFFFFF;
        }
      }
    }
  }
  .input{
    /deep/ .van-cell-group{
      background: none;
    }
    /deep/ .van-cell-group--inset{
      margin: 0;
      .van-cell{
        background: #14181A;
        font-weight: bold;
        font-size: 42px;
        border-bottom: 1px solid #7E8D95;
      }
      .van-field__control{
        text-align: center;
        border: none;
        color: #6FFEE8;
      }
    }
  }
  /deep/ .van-popup{
    background: none;
  }
  .popup-reservation{
    box-sizing: border-box;
    width: 312px;
    height: 260px;
    background: #232A2E;
    border-radius: 25px;
    padding: 28px 19px 0 19px;
    .title{
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
      margin-bottom: 30px;
    }
    .confirm-btn{
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      .confirm{
        width: 187px;
        height: 56px;
        background: linear-gradient(90deg, #57F6CD, #AF65EE);
        box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
        border-radius: 28px;
        font-weight: bold;
        font-size: 17px;
        color: #000000;
        text-align: center;
        line-height: 56px;
        border: none;
      }
    }
    /deep/ .van-cell-group{
      width: 264px;
      height: 62px;
      background: #000000;
      border-radius: 31px;
    }
    /deep/ .van-cell-group--inset{
      margin: 0;
      .van-cell{
        font-weight: 500;
        font-size: 17px;
        color: #7E8D95;
        width: 264px;
        height: 62px;
        background: #000000;
        border-radius: 31px;
      }
      .van-field__control{
        text-align: center;
        font-weight: bold;
        font-size: 21px;
        color: #FFFFFF;
      }
    }
    /deep/.van-field__control::placeholder{
      font-weight: 500;
      font-size: 17px;
      color: #7E8D95;
    }
  }
  .popup-rule{
    box-sizing: border-box;
    width: 312px;
    //height: 260px;
    background: #232A2E;
    border-radius: 25px;
    padding: 28px 19px 33px 19px;
    .title{
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
      margin-bottom: 30px;
    }
    .tips{
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 25px;
    }
  }
}

</style>
