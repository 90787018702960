<template>

  <van-pull-refresh
      v-model="loading"
      @refresh="onRefresh"
      loosing-text=" "
      :loading-text="$t('home.loading')"
      :success-text="$t('home.success')"
      pulling-text=" "
  >
    <div class="indexPage">
      <div class="index-top">
        <van-notice-bar
            background="#242527"
            color="#FFFFFF"
            left-icon="volume-o"
            :text="notice || $t('home.noContent')"
            scrollable
            @click="router.push('/noticeList')"
        />
        <van-icon
            name="setting-o"
            @click="getSet"
        />
      </div>
<!--设置-->
      <van-popup v-model:show="showSet"   :close-on-click-overlay="false"  >
        <div class="set-info">
          <van-radio-group v-model="checkedSet">
            <van-radio :name="1" label-position="left">{{$t('home.setType1')}}</van-radio>
            <van-radio :name="2" label-position="left">{{$t('home.setType2')}}</van-radio>
            <van-radio :name="3" label-position="left">{{$t('home.setType3')}}</van-radio>
          </van-radio-group>
          <div class="set-btn">
            <van-button @click="showSet=!showSet">{{$t('home.qx')}}</van-button>
            <van-button @click="setSubmit">{{$t('home.qd')}}</van-button>
          </div>
        </div>

      </van-popup>

<!--      暂无数据-->
      <div class="no-data" v-if="!pageData.list">
        <img src="../../assets/images/no_data.png" alt=""/>
      </div>

      <div class="my-swipe">
        <div v-for="(item,i) in pageData.list" :key="i" class="van-swipe-item">
          <div class="items">
            <div class="periods-number">
              <div>{{item.gameName}}</div>
              <div class="point"></div>
              <div>{{item.turn}}{{$t('home.q')}}</div>
            </div>
            <div class="crowdfunding-quota">
              <div class="numbers">{{item.disTotalAmount}}</div>
              <div class="text-usdt">{{$t('home.bqzced')}}（USDT）</div>
            </div>
            <div class="participation-quota">
              <van-circle
                  color="#C58FE4"
                  layer-color="#FFFFFF"
                  stroke-width="80"
                  size="116"
                  :current-rate="mul(item.schedule,100)"
                  :rate="100"
                  :speed="100"
                  :text="item.text"
              />
              <div class="quota-text">
                <div class="number">{{item.amount}}</div>
                <div>{{$t('home.cyed')}}</div>
              </div>
            </div>
            <div class="start-end">
              <div class="start">
                <img class="start-image" src="@/assets/images/start.png" alt="">
                <div class="text">{{locale==='en-US'?toHHmmss(item.startTime,-5):toHHmmss(item.startTime,+8)}}</div>
              </div>
              <div class="end">
                <img class="end-image" src="@/assets/images/end.png" alt="">
                <div class="text">{{locale==='en-US'?toHHmmss(item.endTime,-5):toHHmmss(item.endTime,+8)}}</div>
              </div>
            </div>
            <van-button class="btn" v-if="item.reinvestmentStatus && locale==='en-US'" @click="cancellation(item)">{{$t('home.qxft')}}</van-button>
            <van-button class="btn" style="letter-spacing: 5px" v-if="item.reinvestmentStatus && locale==='zh-HK'" @click="cancellation(item)">{{$t('home.qxft')}}</van-button>
          </div>
            <div v-if="item.reservedStatus==1" class="status" >
            </div>
            <div v-else class="status" >
              <div class="success" v-if="item.status===1">{{$t('home.success')}}！</div>
              <div class="success failed" v-if="item.status===2">{{$t('home.failed')}}！</div>
            </div>
        </div>
      </div>

      <van-popup v-model:show="show"  closeable :close-on-click-overlay="false" @close="close" >
        <div class="password">
          <div class="title">{{$t('home.zfmm')}}</div>
          <van-cell-group inset>
            <van-field v-model="payPwd"  center :placeholder="$t('home.srmm')" maxlength="6"/>
          </van-cell-group>
          <div class="confirm-btn">
            <van-button class="confirm" @click="submit">{{$t('home.qd')}}</van-button>
          </div>
        </div>
      </van-popup>
    </div>

  </van-pull-refresh>
</template>

<script setup name="index-page">
import {getHomeData, getNotice, getUserInfo, setCancelReinvestment, setTakeOutType} from "../../api";
import {getPublicKey, mul, toHHmmss} from '../../utils/decimalTool'
import {getCurrentInstance, onBeforeMount, ref} from 'vue'
import {Toast} from "vant";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
const router=useRouter()
const { $t } = getCurrentInstance().proxy;
const {locale } = useI18n()
const loading = ref(false);
const onRefresh = () => {
  setTimeout(() => {
    getNoticeInfo()
    getHttp()
    loading.value = false;
  }, 1000);
};

onBeforeMount(()=>{
  getNoticeInfo()
  getHttp()
})
const pageData=ref({})
const notice=ref('')
const getHttp=()=>{
  getHomeData().then(res=>{
    res.data.list.forEach(v=>{
      v.text=mul(v.schedule,100)+'%'
    })
    pageData.value=res.data

  })

}
const getNoticeInfo=()=>{
  getNotice().then(res=>{
    if(res.data.content){
      notice.value=res.data.content
    }
  })
}
const show=ref(false)
const payPwd=ref('')
const close=()=>{
  show.value=false
  payPwd.value=''
}
const itemInfo=ref({})
const cancellation=(item)=>{
  router.push({
    path:'/cancelReinvestment',
    query:{
      gameId:item.gameId,
      id:item.id
    }
  })
  // itemInfo.value=item
  // payPwd.value=''
  // show.value=true
}
const submit=()=>{
  setCancelReinvestment({
    "gameId":itemInfo.value.gameId, //游戏id
    "gameItemId":itemInfo.value.id, //游戏轮数id
    'payPwd' :getPublicKey(payPwd.value)//公钥加密后
  }).then(res=>{
    if(res.code==='200'){
      // eslint-disable-next-line no-undef
      close()
      Toast($t('home.success'))
      getHttp()
    }else{
      Toast(res.msg)
    }
  })
}

// s设置
const showSet=ref(false)
const checkedSet=ref(1)
const getSet=()=>{
  getUserInfo().then((res)=>{
    checkedSet.value=res.data.takeOutType
    showSet.value=!showSet.value
  })
}
const setSubmit=()=>{
  setTakeOutType({
    type:checkedSet.value
  }).then(res=>{
    if(res.data){
      Toast($t('home.success'))
      showSet.value=false
    }else{
      Toast(res.msg)
    }
  })
}
</script>

<style scoped lang="less">

.index-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
  .van-notice-bar{
    border-radius: 19px;
    border: 1px solid #50817A;
    font-size: 15px;
    height: 38px;
    flex: 1;
  }
  .van-icon-setting-o{
    font-size: 26px;
    color: #50817A;
    margin-left: 10px;
  }
}
.set-info{
  width: 312px;
  padding: 29px 24px;
  box-sizing: border-box;
  .van-radio-group{
    margin: 30px 0;
    font-size: 12px;
    color: #FFFFFF;
  }
  /deep/.van-radio{
    justify-content: space-between;
    margin-bottom: 30px;
    height: 66px;
    background: linear-gradient(90deg, #57F6CD, #AF65EE);
    box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
    border-radius: 28px;
    border: none;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    .van-radio__label{
      font-weight: bold;
      font-size: 17px;
      color: #000000;
      text-align: left;
    }
    .van-radio__icon--checked .van-icon{
      background-color: #6FFEE8 !important;
      color: #000000 !important;
      border-color: #6FFEE8 !important;
    }
  }

  .set-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .van-button{
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
      //color: #c8c9cc;
      background: transparent;
      border: none;
      width: 45%;
    }

  }
}
.indexPage{
  box-sizing: border-box;
  height: 100vh;
  padding: 21px 18px 90px 18px;
  background-image: url("~@/assets/images/bg_pic.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
  .my-swipe {
    flex: 1;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    ///deep/.van-swipe__track{
    //  //flex-direction: column;
    //  //overflow-y: scroll;
    //  width: 100%;
    //}
    .van-swipe-item {
      box-sizing: border-box;
      padding: 24px 10px 0px 10px;
      position: relative;
      background: #000000;
      border-radius: 25px;
      height: auto !important;
      margin-bottom: 35px;
      .items{
        display: flex;
        flex-direction: column;
        align-items: center;
        .periods-number{
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 18px;
          color: #FFFFFF;
          .point{
            margin: 0 8px;
            width: 5px;
            height: 5px;
            background: #6FFEE8;
            border-radius: 50%;
          }
        }
        .crowdfunding-quota{
          margin-top: 10px;
          width: 229px;
          height: 134px;
          background-image: url("~@/assets/images/jhed.png");
          background-repeat: no-repeat;
          background-size: contain;
          .numbers{
            text-align: center;
            margin-top: 20px;
            font-weight: bold;
            font-size: 35px;
            color: #6FFEE8;
          }
          .text-usdt{
            text-align: center;
            margin-top: 15px;
            font-weight: 500;
            font-size: 15px;
            color: #81ACD7;
          }
        }
        .participation-quota{
          background: linear-gradient(0deg, rgba(149,132,238,0.3), rgba(0,0,0,0.3));
          border-radius: 16px;
          margin-top: 14px;
          box-sizing: border-box;
          padding: 30px 18px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/ .van-circle__text{
            font-weight: bold;
            font-size: 23px;
            color: #AF65EE;
          }
          .quota-text{
            flex: 1;
            text-align: center;
            font-weight: bold;
            font-size: 13px;
            color: #A2ADC6;
            .number{
              margin-bottom: 14px;
              font-weight: bold;
              font-size: 25px;
              color: #FFFFFF;
            }
          }
        }
        .start-end{
          text-align: center;
          margin-bottom: 25px;
          .start, .end{
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            .start-image,.end-image{
              margin-right: 15px;
              width: 18px;
              height: 18px;
            }
            .text{
              font-weight: bold;
              font-size: 16px;
              color: #FFFFFF;
              opacity: 0.7;
            }
          }
        }
        .btn{
          margin-bottom: 25px;
          width: 200px;
          height: 56px;
          background: linear-gradient(90deg, #57F6CD, #AF65EE);
          box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
          border-radius: 28px;
          font-weight: bold;
          font-size: 17px;
          color: #000000;
          border: none;
        }

      }
      .status{
        //position: absolute;
        //left: 0;
        //bottom: 0;
        width: 106%;
        display: flex;
        align-items: flex-end;
        //margin-top: 25px;
        margin-left: -10px;
      }
      .success{
        width: 100%;
        height: 56px;
        background: #68CF63;
        border-radius: 0px 0px 25px 25px;
        font-weight: bold;
        font-size: 19px;
        color: #FFFFFF;
        text-align: center;
        line-height: 56px;
      }
      .failed{
        width: 100%;
        background: #FF5A5A;
      }
      .removal{
        background: #FF6C37;
      }
    }
  }
}
/deep/.van-popup {
  background: #232A2E;
  border-radius: 25px;
}
/deep/.van-popup__close-icon--top-right{
  top:30px;
  height: 24px;
}
.password{
  box-sizing: border-box;
  width: 312px;
  //height: 320px;
  background: #232A2E;
  border-radius: 25px;
  padding: 28px 19px 31px 19px;
  .title{
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 30px;
  }
  .confirm-btn{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    .confirm{
      width: 187px;
      height: 56px;
      background: linear-gradient(90deg, #57F6CD, #AF65EE);
      box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
      border-radius: 28px;
      font-weight: bold;
      font-size: 17px;
      color: #000000;
      text-align: center;
      line-height: 56px;
      border: none;
    }
  }
  /deep/ .van-cell-group{
    width: 100%;
    height: 62px;
    background: #000000;
    border-radius: 31px;
  }
  /deep/ .van-cell-group--inset{
    margin: 0;
    .van-cell{
      font-weight: 500;
      font-size: 17px;
      color: #7E8D95;
      width: 264px;
      height: 62px;
      background: #000000;
      border-radius: 31px;
    }
    .van-field__control{
      text-align: center;
      border: none;
      color: #6FFEE8;
    }
  }
}
.no-data{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  img{
    width: 176px;
    height: 258px;
  }
}
</style>
