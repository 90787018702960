<template>
  <IndexPage  v-if="activeTab===0" ></IndexPage>
  <ReservationPage  v-if="activeTab===1" ></ReservationPage>
  <SECPage  v-if="activeTab===2" ></SECPage>
  <PropertyPage  v-if="activeTab===3" ></PropertyPage>
  <MyPage  v-if="activeTab===4" ></MyPage>
  <van-tabbar v-model="activeTab" @change="changeTab">
    <van-tabbar-item >
      <template #icon="props">
        <img :src="props.active?require('../assets/images/zc_press.png'):require('../assets/images/zc.png')"  alt=""/>
        <div :class="props.active ? 'tabbarTextActive' :'tabbarText'">{{$t('home.zc')}}</div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item >
      <template #icon="props">
        <img :src="props.active?require('../assets/images/yy_press.png'):require('../assets/images/yy.png')"  alt=""/>
        <div :class="props.active ? 'tabbarTextActive' :'tabbarText'">{{ $t('home.yy') }}</div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item>
      <template #icon="props" >
        <img :src="props.active?require('../assets/images/sec_press.png'):require('../assets/images/sec.png')"  alt=""/>
      </template>
    </van-tabbar-item>
    <van-tabbar-item>
      <template #icon="props" >
        <img :src="props.active?require('../assets/images/zichan_press.png'):require('../assets/images/zichan.png')"  alt=""/>
        <div :class="props.active ? 'tabbarTextActive' :'tabbarText'">{{ $t('home.zcc') }}</div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item>
      <template #icon="props" >
        <img :src="props.active?require('../assets/images/my_press.png'):require('../assets/images/my.png')"  alt=""/>
        <div :class="props.active ? 'tabbarTextActive' :'tabbarText'">{{ $t('home.wd') }}</div>
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>
<script setup>
import {ref,onBeforeMount,getCurrentInstance,onBeforeUnmount,} from 'vue'

// tab切换
const activeTab = ref(0);
// eslint-disable-next-line no-unused-vars
const { $t } = getCurrentInstance().proxy;



const changeTab = (val) => {
  if(val===0) sessionStorage.setItem('activeTab','0')
  if(val===1) sessionStorage.setItem('activeTab','1')
  if(val===2) sessionStorage.setItem('activeTab','2')
  if(val===3) sessionStorage.setItem('activeTab','3')
  if(val===4) sessionStorage.setItem('activeTab','4')
}
onBeforeMount(()=>{

  if(sessionStorage.getItem('activeTab')){
    activeTab.value=Number(sessionStorage.getItem('activeTab'))
  }


})
onBeforeUnmount(()=>{
  window.scrollTo(0,0)
})

import IndexPage from "@/views/components/index-page.vue";
import ReservationPage from "@/views/components/reservation-page.vue";
import PropertyPage from "@/views/components/property-page.vue";
import MyPage from "@/views/components/my-page.vue";
import SECPage from "@/views/components/sec-page.vue";




</script>
<style scoped lang="less">
.flex_between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.van-hairline--top-bottom:after{
  border-width: 0 0;
}
.van-tabbar{
  padding-bottom: 30px;
  padding-top: 10px;
  background: #252B2E;
  border-radius: 20px 20px 0 0;
  .van-tabbar-item--active{
    background: #252B2E;
    border-radius: 20px 20px 0 0;
  }
  .tabbarText{
    font-weight: 500;
    font-size: 12px;
    color: #6C7A81;
  }
  .tabbarTextActive{
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
  }
  img{
    width: 30px;
    height: 30px;
    margin-bottom: 3px;
  }
  /deep/.van-tabbar-item__icon{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

}
.sy{
  height:100vh;
  .top{
    background: #00A779;
    padding: 11px 15px;
    .connect{
      background: #FF8800;
      border-radius: 17px 17px 17px 17px;
      opacity: 1;
      font-size: 14px;
      font-weight: bold;
      color: #FFFFFF;
      padding: 7px 18px;
    }
    .language{
      background: #FFFFFF;
      border-radius: 14px 14px 14px 14px;
      font-size: 14px;
      font-weight: bold;
      color: #00A779;
      padding: 4px 12px;
    }
  }

  .cards{
    width: 100%;
    height: 40%;
    padding: 0px 15px ;
    margin-top: -28px;
    box-sizing: border-box;
    .card{
      width: 47%;
      //height: 164px;
      height: 91%;
      max-height: 164px;
      background: #FFFFFF;
      box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.05);
      border-radius: 15px 15px 15px 15px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 17px;
      .number{
        font-size: 24px;
        color: #00A779;
      }
      .status{
        font-size: 24px;
        font-weight: 800;
        color: #FF6F00;
      }
      .name{
        font-size: 15px;
        font-weight: bold;
        color: #04102A;
        margin-top: 25px;
      }
    }
  }
}


</style>
