<template>
  <div class="sec">
    <div class="sec-top">
      <span>SEC/USDT</span>
      <van-icon name="question-o" @click="clickFunction('showQuestion')"/>
    </div>
    <van-popup v-model:show="showQuestion" closeable  :close-on-click-overlay="false"  >
      <div class="question-info">
        <p style="text-align: center">{{$t('home.secQuestion')}}</p>
        <p>{{$t('home.secQuestion1')}}</p>
        <p>{{$t('home.secQuestion2')}}</p>
        <p>{{$t('home.secQuestion3')}}</p>
        <p>{{$t('home.secQuestion4')}}</p>
      </div>

    </van-popup>
    <div class="count">
      <div>
        <strong>{{secPriceNum.price}}</strong>
        <span>{{secPriceNum.compareRate}}%</span>
      </div>
      <div>
        <p style="margin-bottom: 5px">{{$t('home.jrfx')}} {{secPriceNum.openPrice}}</p>
        <p>{{$t('home.jrzg')}} {{secPriceNum.highestPrice}}</p>
      </div>
    </div>
    <div class="echarts-k" ref="echarts_k"></div>
    <div class="operation">
      <div class="amount">
        <p>{{$t('home.ye')}}（SEC）</p>
        {{secBalanceNum}}
      </div>
      <div class="operation-btn">
        <van-button @click="clickFunction('showExchange')">{{$t('home.dc')}}</van-button>
        <van-button @click="clickFunction('showTransfer')">{{$t('home.zz')}}</van-button>
      </div>
    </div>
    <van-popup v-model:show="showExchange"  closeable :close-on-click-overlay="false"  @close="close">
      <div class="transaction">
        <div class="title">{{$t('home.dc')}}</div>
        <div class="input">
          <van-cell-group inset>
            <van-field
                v-model="exchange"
                center
                :placeholder="$t('home.dcp')"
                @blur="changeExchange(exchange)"
            />
          </van-cell-group>
          <span @click="changeExchange(secBalanceNum)">{{$t('home.qb')}}</span>
        </div>

        <div class="num flex-between">
          {{$t('home.dqjg')}}：
          <strong>{{secPriceNum.price}}</strong>
        </div>
        <div class="num flex-between">
          {{$t('home.yjhd')}}USDT：
          <strong style="color: #AF65EE">{{usdtNum}}</strong>
        </div>
        <div class="confirm-btn">
          <van-button class="confirm" @click="submitExchange">{{$t('home.qrdc')}}</van-button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model:show="showTransfer"  closeable :close-on-click-overlay="false"  @close="close">
      <div class="transaction">
        <div class="title">{{$t('home.zz')}}</div>
        <div class="input">
          <van-cell-group inset>
            <van-field v-model="transfer"  center :placeholder="$t('home.zzp')"  @blur="changeTransfer(transfer)"/>
          </van-cell-group>
          <span @click="changeTransfer(secBalanceNum)">{{$t('home.qb')}}</span>
        </div>
          <van-cell-group inset class="input input2">
            <van-field v-model="transferAddress"  center :placeholder="$t('home.dfdz')" />
          </van-cell-group>

        <div class="num flex-between">
          {{$t('home.yjhd')}}SEC
          <strong>{{secNum}}</strong>
        </div>

        <div class="confirm-btn">
          <van-button class="confirm" @click="submitTransfer">{{$t('home.qrzc')}}</van-button>
        </div>
      </div>
    </van-popup>
    <div class="detailed">
      <div class="detailed-tittle">
        <span>{{$t('home.bdmx')}}</span>
        <div class="zk" @click="clickFunction('showPicker')">
          {{columnObj[value].text}}
          <img src="../../assets/images/zk.png" alt=""/>
        </div>
      </div>
      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker
            v-model="value"
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
            :confirm-button-text="$t('home.qd')"
            :cancel-button-text="$t('home.qx')"
        />
      </van-popup>

      <van-list
          v-model:loading="loading"
          :finished="finished"
          :finished-text="$t('home.noMore')"
          :loading-text="$t('home.loading')"
          :error-text="$t('home.failed')"
          @load="onLoad"
      >
        <div v-for="(item,i) in list" :key="i" class="list_info">
          <div>
            {{columnObj[item.type].text}}
            <p>{{locale==='en-US'?toHHmmss(item.createTime,-5):toHHmmss(item.createTime,+8)}}</p>
          </div>
          <span :style="item.amount<0?'color:#FF5A5A':''">{{item.amount}}</span>
        </div>
      </van-list>
    </div>
  </div>

</template>
<script setup name="sec-page">
import * as echarts from "echarts";
import {getCurrentInstance, onMounted, ref} from "vue";
import {exchangeUsdt, kline, secBalance, secLog, secPrice, transferSec} from "../../api";
import {Toast} from "vant";
import {mul, toHHmmss, get2String, get5number} from '../../utils/decimalTool'
import {useI18n} from "vue-i18n";
const {locale } = useI18n()
const { $t } = getCurrentInstance().proxy;
const showPicker=ref(false)
const value=ref(0)

const columns =ref([
  { text: $t('home.qb'), value: 0 },
  { text: $t('home.lxjl'), value: 1 },
  { text: $t('home.dthl'), value: 2 },
  { text: $t('home.vipjl'), value: 3 },
  { text: $t('home.thbj'), value: 8 },
  { text: $t('home.jslx'), value: 9 },
  { text: $t('home.jsbj'), value: 10 },
  { text: $t('home.jjff'), value: 11 },
  { text: $t('home.sysljl'), value: 12 },
  { text: $t('home.pjjl'), value: 13 },
  { text: 'SEC '+$t('home.dc'), value: 15 },
  { text: 'SEC '+$t('home.zzkc'), value: 16 },
  { text: 'SEC '+$t('home.zzrz'), value: 17 },
])
const columnObj=ref((function() {
  let obj = {};
  columns.value.forEach(v=>{
    obj[v["value"]] = v;
  })
  return obj;
})());
const onConfirm=(val)=>{
  value.value=val.value
  showPicker.value=false
  list.value=[]
  pageNo.value=1
  total.value=0
  getList()
}


const echarts_k = ref(null);
const getKLine=(dateArr,dataArr)=>{
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        link: {xAxisIndex: 'all'},//上下划过分开展示还是一起展示
      },
      textStyle: {
        color: '#7e8d95'
      },
      formatter: function (params) {//修改鼠标划过显示为中文
        const name=params[0].name
        const data=params[0].data
        return name + '<br>' +
            '<p> ' +$t('home.kpj')+':' + data[1]+'</p>'+
            '<p> ' +$t('home.spj')+':' + data[2]+'</p>'+
            '<p> ' +$t('home.zdj')+':' + data[3]+'</p>'+
            '<p> ' +$t('home.zgj')+':' + data[4]+'</p>'
      }

      // extraCssText: 'width: 170px'
    },
    xAxis: {
      data: dateArr,
    },
    grid: {
      left: "9%",
      right: "8%",
      top: "5%",
      bottom: "10%",
    },
    yAxis: {
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      // scale: true,
      // splitArea: {
      //   show: true
      // }
    },
// 时间轴
//   dataZoom: [{
//     type: "inside",
//     start: 10,
//     end: 30
//   }, {
//     show: true,
//     type: "slider",
//     top: "90%",
//     start: 10,
//     end: 30
//   }],
    series: [
      {
        name:'sec',
        type: "candlestick",
        data: dataArr,
        itemStyle: {
          color: '#47B363', // 上涨K线颜色
          color0: '#EB5455', // 下跌K线颜色
          borderColor: '#47B363', // 上涨K线颜色
          borderColor0: '#EB5455', // 下跌K线颜色
        }
      },
    ],
  }
  let chart = echarts.init(echarts_k.value);
  option && chart.setOption(option);
  window.addEventListener("resize", () => {
    chart.resize();
  });
}
const secPriceNum=ref({})
const secBalanceNum=ref(null)
const getHttp=()=>{
  secPrice().then(res=>{
    res.data.price=get5number(res.data.price)
    res.data.compareRate=get5number(res.data.compareRate)
    res.data.openPrice=get5number(res.data.openPrice)
    res.data.highestPrice=get5number(res.data.highestPrice)
    secPriceNum.value=res.data
  })
  kline({
    "pageSize":29,
    "pageNo":1
  }).then(res=>{
    if(res.data.dateArr.length>0){
      getKLine(res.data.dateArr,res.data.dataArr)
    }else{
      Toast(res.msg)
    }
  })
  secBalance().then(res=>{
    secBalanceNum.value=get2String(res.data)
  })
  getList()

}
const getList=()=>{
  secLog({
    "pageSize":10,
    "pageNo":pageNo.value,
    "logType":value.value===0?null:value.value //日志类型 1：利息奖励（游戏成功结算） 2： 动态奖励  3： 经理奖励  8 ：退还本金（游戏成功时结算本金） 9 ：结算利息（游戏失败）10 ：结算本金（游戏失败）11 ：奖金发放 12：剩余数量奖励 13 ：平级奖励 15： SEC兑出  16 ：SEC转账扣除  17：SEC转账入账  18 ：兑入USDT
  }).then(res=>{
    if(res.data.list!==null){
      list.value.push(...res.data.list)
      total.value=res.data.total
    }

  })
}

const pageNo=ref(1)
const total=ref(0)
const list = ref([]);
const loading = ref(false);
const finished = ref(false);
const onLoad = () => {
  // 异步更新数据
  // setTimeout 仅做示例，真实场景中一般为 ajax 请求
  setTimeout(() => {

    // 数据全部加载完成
    if (list.value.length >= total.value) {
      finished.value = true;
    }else {
      pageNo.value+=1
      getList()
      loading.value = false;

    }
  }, 1000);
};
const showQuestion=ref(false)
const get2number=(val)=>{
  const reg = /^\d+(.\d{1,2})?$/g;
  let number
  if (reg.test(val)) { //正则匹配通过，提取有效文本
    number = val;
  } else { //正则匹配不通过，直接清空
    number = '';
    Toast($t('home.get2number'))
  }
  return number
}

// 兑出
const showExchange=ref(false)
const exchange=ref(null)
const usdtNum=ref('0')

const changeExchange=(val)=>{
  exchange.value=get2number(val)
  let priceNum=Number(secPriceNum.value.price)
  let exchangeRateNum=Number(secPriceNum.value.exchangeRate)
  let num1=mul(exchange.value,priceNum,)
  let num=mul(num1,(1-exchangeRateNum))
  usdtNum.value=get2String(num)

}
const submitExchange=()=>{
  if(exchange.value===null || exchange.value==0){
    Toast($t('home.dcp'))
    return
  }
  Toast.loading({
    duration:0,
    forbidClick:true,
    message:$t('home.loading')
  })
  exchangeUsdt({
    "secNum":exchange.value //兑出数量
  }).then(res=>{
    Toast.clear();
    if(res.code==='200'){
        showExchange.value=false
        Toast($t('home.success'))
        list.value=[]
        pageNo.value=1
        finished.value=false
        getHttp()
        close()
      }else{
      Toast(res.msg)
    }
  })
      .catch(()=>{
        Toast.clear();
      })
}
// 转账
const showTransfer=ref(false)
const transfer=ref(null)
const transferAddress=ref(null)
const secNum=ref('0')

const changeTransfer=(val)=>{
  transfer.value=get2number(val)
  let transferRateNum=Number(secPriceNum.value.transferRate)
  let num=mul(transfer.value,(1-transferRateNum))
  secNum.value=get2String(num)
}
const submitTransfer=()=>{
  if(transfer.value===null || transfer.value==0){
    Toast($t('home.zzp'))
    return
  }
  if(transferAddress.value===null || transferAddress.value===''){
    Toast($t('home.dfdz'))
    return
  }
  Toast.loading({
    duration:0,
    forbidClick:true,
    message:$t('home.loading')
  })
  transferSec({
    "transferNum":transfer.value, //转账数量
    "address":transferAddress.value //转账地址
  }).then(res=>{
    Toast.clear();
    if(res.code==='200'){
      showTransfer.value=false
      Toast($t('home.success'))
      list.value=[]
      pageNo.value=1
      finished.value=false
      getHttp()
      close()

    }else{
      Toast(res.msg)
    }
  })
      .catch(()=>{
        Toast.clear();
      })
}

const close=()=>{
  exchange.value=null
  transfer.value=null
  transferAddress.value=null
  usdtNum.value='0'
  secNum.value='0'
}
const clickFunction=(name)=>{
  let chart = echarts.init(echarts_k.value);
  chart.dispatchAction({type: 'hideTip'});
  if(name==='showQuestion') showQuestion.value=!showQuestion.value
  if(name==='showExchange') showExchange.value=!showExchange.value
  if(name==='showTransfer') showTransfer.value=!showTransfer.value
  if(name==='showPicker') showPicker.value=true
}
onMounted(()=>{
  getHttp()
})
</script>
<style scoped lang="less">
.sec{
  padding-bottom: 90px;
  min-height: 100vh;
  box-sizing: border-box;
}
.sec-top{
  text-align: center;
  font-weight: bold;
  font-size: 17px;
  color: #FFFFFF;
  padding: 19px 0;
  .van-icon-question-o{
    position: absolute;
    top:19px;
    right:18px;
    font-size: 18px;
  }
}
/deep/.van-popup {
  background: #232A2E;
  border-radius: 25px;
}
/deep/.van-popup__close-icon--top-right{
  top:30px;
  height: 24px;
}
.question-info{
  width: 312px;
  padding: 29px 24px;
  box-sizing: border-box;
  p{
    font-size: 15px;
    color: #ffffff;
    text-align: left;
    margin-top: 0px;
  }
}
.count{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  div{
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: space-between;
  }
  strong{
    font-size:34px;
    color: #6FFEE8;
    margin-bottom: 3px;

  }
  span{
    font-size: 14px;
    color: #6FFEE8;
  }
  p{
    font-size: 15px;
    color: #7E8D95;
    margin: 0;
  }
}
.transaction{
  box-sizing: border-box;
  width: 312px;
  //height: 320px;
  background: #232A2E;
  border-radius: 25px;
  padding: 28px 19px 31px 19px;
  .title{
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 10px;
  }

  .num{
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    width: 90%;
    margin: 15px  0;
    text-align: left;
    strong{
      font-weight: bold;
      font-size: 14px;
      color: #6FFEE8;
    }
  }
  .tips{
    font-weight: 500;
    font-size: 12px;
    color: #7E8D95;
    margin-top: 17px;
  }
  .confirm-btn{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    .confirm{
      width: 187px;
      height: 56px;
      background: linear-gradient(90deg, #57F6CD, #AF65EE);
      box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
      border-radius: 28px;
      font-weight: bold;
      font-size: 17px;
      color: #000000;
      text-align: center;
      border: none;
    }
  }
  .input{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    //height: 62px;
    padding:  10px 16px;
    border-bottom: 1px solid #7E8D95;
    border-radius: 0px;
    span{
      font-size: 21px;
      color: #6FFEE8;
      margin-left: 16px;
      position: absolute;
      right: 16px;
    }
    /deep/.van-cell{
      width: 210px ;

    }
  }
  .input2{
    margin: 16px 0 !important;
    /deep/.van-cell{
      width: 100%;

    }
  }
  /deep/ .van-cell-group{
    background: none;
    flex: 1;
  }
  /deep/ .van-cell-group--inset{
    margin: 0;
    .van-cell{
      background: transparent;
      padding: 0;
    }
    .van-field__control{
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
    }
    .van-field__control::placeholder{
      font-weight: normal;
      font-size: 15px;
    }
  }
  /deep/ .van-cell:after{
    border: none;
  }
}

.echarts-k{
  background: black;
  width: 100%;
  height: 180px;
  padding-bottom: 5px;
  //box-sizing: border-box;
  /deep/ p{
    text-align: left;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
.operation{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 18px;
  border-bottom: 1px solid #ffffff;
  .amount{
    flex-direction: column;
    display: flex;
    font-size: 30px;
    color: #FFFFFF;
    align-items: self-start;
    p{
      font-size: 15px;
      color: #7E8D95;
      margin: 0;
      margin-bottom: 5px;
    }
  }
  .operation-btn{
    display: flex;
    align-items: center;
    .van-button{
      width: 100px;
      height: 40px;
      background: linear-gradient(90deg, #57F6CD, #AF65EE);
      box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
      border-radius: 28px;
      font-weight: bold;
      font-size: 15px;
      color: #000000;
      border: none;
      margin-right: 8px;
    }
  }
}
.detailed{
  padding: 25px 18px;
  .detailed-tittle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    font-weight: bold;
    color: #FFFFFF;
    .zk{
      font-size: 15px;
      color: #6FFEE8;
      display: flex;
      align-items: center;
      font-weight: normal;
      img{
        width: 11px;
        height: 6px;
        margin-left: 5px;
      }
    }
  }
  /deep/.van-popup {
    background: #232A2E;
  }
  /deep/.van-picker{
    background: #232A2E;
  }
  /deep/.van-picker-column__item{
    color: #FFFFFF;
  }
  /deep/.van-picker__confirm{
    color: #6FFEE8;
  }

  /deep/.van-picker__mask{
    background: none;
  }
  .no-data{

    img{
      width: 176px;
      height: 258px;
      margin: 40px auto;
    }
  }
  .list_info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    //border-bottom: 1px dashed #7E8D95;
    border-bottom: 1px solid #7E8D95;
    padding: 18px 0;
    div{
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: self-start;
      p{
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 0;
        color: #7E8D95;
      }
    }
    span{
      font-weight: bold;
      font-size: 20px;
      color: #6FFEE8;
    }
  }
}
</style>
