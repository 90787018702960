<template>
<div class="my">
  <div class="top">
    <div >
      <img src="../../assets/images/tx.png" alt=""/>
      {{userInfo.nickName}}
    </div>
    <p>{{getSubLevel(userInfo.level)}}</p>
  </div>
  <div class="gard">
    <div class="gard-item" @click="jumpUrl('/myTeam')">
      <img src="../../assets/images/team.png" alt="">
      <strong>{{$t('home.wdtd')}}</strong>
    </div>
    <div class="gard-item"  @click="jumpUrl('/share')">
      <img src="../../assets/images/share.png" alt="">
      <strong>{{$t('home.fxhtg')}}</strong>
    </div>
    <div class="gard-item" @click="jumpUrl('/switchLanguage')">
      <img src="../../assets/images/languge.png" alt="">
      <strong>{{$t('home.lange')}} </strong>
      <span>{{lange}}</span>
    </div>
    <div class="gard-item" @click="show=true">
      <img src="../../assets/images/sign_out.png" alt="">
      <strong>{{$t('home.tc')}}</strong>
    </div>
  </div>

  <van-popup v-model:show="show" closeable>
    <div class="title">{{$t('home.tcdl')}}</div>
    <div class="tips">{{$t('home.qrtcdl')}}？</div>
    <van-button class="submit" @click="logOut">{{$t('home.qd')}}</van-button>
  </van-popup>
</div>
</template>
<script setup>
import {getCurrentInstance, onBeforeMount, ref} from 'vue'
import {getSubLevel} from '../../utils/decimalTool'
import {getUserInfo} from '../../api'
import { useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
const { $t } = getCurrentInstance().proxy;
const {locale } = useI18n()
const router = useRouter()
const jumpUrl=(url)=>{
  router.push(url)
}
const show=ref(false)
const logOut=()=>{
  // sessionStorage.removeItem('token')
  // sessionStorage.removeItem('address')
  // sessionStorage.removeItem('userId')
  // sessionStorage.setItem('activeTab',0)
  router.push('/')
}
const userInfo=ref({})
const getHttp=()=>{
  getUserInfo().then(res=>{
    let nickName=res.data.address
    res.data.nickName= nickName.substring(0, 4) +
        '****' +
        nickName.substr(nickName.length - 4)
    userInfo.value=res.data
  })
}
const lange=ref('')
onBeforeMount(()=>{
  getHttp()
  lange.value=locale.value==='zh-HK'?'中文繁體':'English'
})
</script>
<style scoped lang="less">
.my{
  padding: 21px 18px 100px 18px;
  background-image: url("~@/assets/images/bg_pic.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  min-height: 100vh;
  box-sizing: border-box;
}
.top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  div{
    font-weight: bold;
    font-size: 21px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    img{
      width: 73px;
      height: 73px;
      margin-right: 11px;
    }
  }
  p{
    line-height: 18px;
    background: #FF5454;
    border-radius: 2px;
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    padding: 0 6px;
  }
}
.gard{
  display: grid;
  grid-template-columns: 50% 50%;
  .gard-item{
    width: 95%;
    height: 139px;
    background: linear-gradient(-90deg, #262C2F, #253636);
    border-radius: 10px;
    padding: 25px 0 21px 15px;
    display: flex;
    align-items: start;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 14px;
    img{
      width: 35px;
      height: 35px;
      margin-bottom: 15px;
    }
    strong{
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
    }
    span{
      font-weight: bold;
      font-size: 14px;
      color: #7E8D95;
      margin-top: 5px;
    }
  }
}

/deep/.van-popup{
  width: 312px;
  height: 239px;
  background: #232A2E;
  border-radius: 25px;
  padding: 29px 29px 36px 29px;
  box-sizing: border-box;
  .van-popup__close-icon--top-right{
    top:29px;
    right: 24px;
  }
  .title{
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 35px;
  }
  .tips{
    font-weight: 500;
    font-size: 17px;
    color: #FFFFFF;
    margin-bottom: 28px;
  }
  .submit{
    width: 187px;
    height: 56px;
    background: linear-gradient(90deg, #57F6CD, #AF65EE);
    box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
    border-radius: 28px;
    border: none;
    font-weight: bold;
    font-size: 17px;
    color: #000000;
  }
}
</style>
